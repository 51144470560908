import React, { useState, useEffect } from 'react';
import Context from './index';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import * as ga from '../../utils/ga';

const getLocalCartItems = () => {
  try {
    const list = localStorage.getItem('cartList');
    if (list === null) {
      return [];
    } else {
      return JSON.parse(list);
    }
  } catch (err) {
    return [];
  }
};

const getSavedObservation = () => {
  return localStorage.getItem('cartObservation') || '';
};

const getSavedShippingMethod = () => {
  return localStorage.getItem('shippingMethod');
};

const getSavedDeliveryMethod = () => {
  return localStorage.getItem('deliveryMethod')
    ? JSON.parse(localStorage.getItem('deliveryMethod'))
    : null;
};

const getSavedPaymentMethod = () => {
  return localStorage.getItem('paymentMethod');
};

const getSavedAddress = () => {
  return localStorage.getItem('address')
    ? JSON.parse(localStorage.getItem('address'))
    : null;
};

const CartProvider = (props) => {
  const router = useRouter();
  const { t } = useTranslation('common');
  const [cartItems, setCartItems] = useState(getLocalCartItems());
  const [cartTotal, setCartTotal] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [observation, setObservation] = useState(getSavedObservation);

  const [step, setStep] = useState(router.query.step || 1);
  const [shippingMethod, setShippingMethod] = useState(getSavedShippingMethod);
  const [deliveryMethod, setDeliveryMethod] = useState(getSavedDeliveryMethod);
  const [paymentMethod, setPaymentMethod] = useState(getSavedPaymentMethod);
  const [change, setChange] = useState(0);
  const [address, setAddress] = useState(getSavedAddress);

  useEffect(() => {
    const Total = cartItems.reduce((a, b) => a + b.total, 0);
    setCartTotal(Total);

    localStorage.setItem('cartList', JSON.stringify(cartItems));
  }, [cartItems]);

  useEffect(() => {
    localStorage.setItem('cartObservation', observation || '');
  }, [observation]);

  useEffect(() => {
    localStorage.setItem('shippingMethod', shippingMethod || '');
  }, [shippingMethod]);

  useEffect(() => {
    localStorage.setItem('paymentMethod', paymentMethod || '');
  }, [paymentMethod]);

  useEffect(() => {
    localStorage.setItem('deliveryMethod', JSON.stringify(deliveryMethod));
  }, [deliveryMethod]);

  useEffect(() => {
    localStorage.setItem('address', JSON.stringify(address));
  }, [address]);

  const getTotalValue = () => {
    let value = 0;
    value += cartTotal;
    if (deliveryMethod) {
      value += deliveryMethod.defaultValue;
    }
    return value;
  };

  // Add Product To Cart
  const addToCart = (item, quantity, variation) => {
    if(quantity <= 0){
      toast.error(t('Enter Valid Quantity'));
      return;
    }
    if(item.stockControl){
      if(variation){
        if(variation.currentStock < quantity){
          toast.warning(t('Quantity Unavailable'));
          return;
        }
      }else{
        if(item.currentStock < quantity){
          toast.warning(t('Quantity Unavailable'));
          return;
        }
      }
    }
    toast.success(t('Product Added Successfully'));
    ga.event({
      action: 'addToCart',
      params: {
        item: item,
        quantity: quantity,
        variation: variation,
      },
    });
    let index = -1;
    if (variation === null) {
      index = cartItems.findIndex((itm) => itm._id === item._id);
    } else {
      index = cartItems.findIndex(
        (itm) => itm._id === item._id && itm.variation.id === variation.id
      );
    }

    if (index !== -1) {
      cartItems[index] = {
        ...item,
        qty: quantity,
        total: (item.promoPrice ? item.promoPrice : item.salePrice) * quantity,
      };
      if (variation !== null) {
        cartItems[index].name = cartItems[index].name + ' - ' + variation.name;
        cartItems[index].promoPrice = variation.promoPrice;
        cartItems[index].salePrice = variation.salePrice;
        cartItems[index].total =
          (cartItems[index].promoPrice
            ? cartItems[index].promoPrice
            : cartItems[index].salePrice) * quantity;
        cartItems[index].variation = variation;
      }
      setCartItems([...cartItems]);
    } else {
      let product = {
        ...item,
        qty: quantity,
        total: (item.promoPrice ? item.promoPrice : item.salePrice) * quantity,
      };
      if (variation !== null) {
        product.name = product.name + ' - ' + variation.name;
        product.promoPrice = variation.promoPrice;
        product.salePrice = variation.salePrice;
        product.total =
          (product.promoPrice ? product.promoPrice : product.salePrice) *
          quantity;
        product.variation = variation;
      }
      setCartItems([...cartItems, product]);
    }
  };

  const removeFromCart = (item) => {
    toast.error(t('Product Removed Successfully'));
    setCartItems(cartItems.filter((e) => e._id !== item._id));
  };

  const minusQty = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const plusQty = (item) => {
    setQuantity(quantity + 1);
  };

  // Update Product Quantity
  const updateQty = (item, quantity) => {
    if (quantity >= 1) {
      if (item.stockControl && quantity > item.currentStock) {
        toast.warning(t('Quantity Unavailable'));
        return;
      }
      const index = cartItems.findIndex((itm) => itm._id === item._id);
      if (index !== -1) {
        cartItems[index] = {
          ...item,
          qty: quantity,
          total:
            (item.promoPrice ? item.promoPrice : item.salePrice) * quantity,
        };
        setCartItems([...cartItems]);
        toast.info(t('Product Quantity Updated'));
      } else {
        const product = {
          ...item,
          qty: quantity,
          total:
            (item.promoPrice ? item.promoPrice : item.salePrice) * quantity,
        };
        setCartItems([...cartItems, product]);
        toast.success(t('Product Added Updated'));
      }
    } else {
      toast.error(t('Enter Valid Quantity'));
    }
  };

  const clearCart = () => {
    setCartItems([]);
    setCartTotal(0);
    setQuantity(1);
    setObservation('');
    setStep(1);
    setShippingMethod('');
    setPaymentMethod('');
    setDeliveryMethod('');
    setChange(0);
    setAddress(null);
  };

  return (
    <Context.Provider
      value={{
        ...props,
        cartItems,
        cartTotal,
        setQuantity,
        quantity,
        addToCart: addToCart,
        removeFromCart: removeFromCart,
        plusQty: plusQty,
        minusQty: minusQty,
        updateQty: updateQty,
        clearCart,
        observation,
        setObservation,
        step,
        setStep,
        shippingMethod,
        setShippingMethod,
        deliveryMethod,
        setDeliveryMethod,
        paymentMethod,
        setPaymentMethod,
        change,
        setChange,
        address,
        setAddress,
        getTotalValue,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export default CartProvider;
