import db from '../../utils/db';
import Store from './Store';

function subDays (date, days)  {
  var result = new Date(date);
  result.setDate(result.getDate() - days);
  return result;
}

const getStore = async (hostname) => {
  await db.connect();
  const store = await Store.findOne({
    domains: hostname,
    isActive: true,
    expiresAt: {
      $gte: subDays(new Date(), 2),
    },
  }).lean();
  await db.disconnect();
  if (!store) {
    return null;
  }
  return db.convertDocToObj(store);
};
export { getStore };
