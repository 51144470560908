/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import '../public/assets/scss/app.scss';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress'; //nprogress module
import Head from 'next/head';
import SettingProvider from '../utils/theme-setting/SettingProvider';
import CartContextProvider from '../utils/cart/CartContext';
import { ToastContainer } from 'react-toastify';
import FilterProvider from '../utils/filter/FilterProvider';
import CategoryProvider from '../utils/categories/CategoryProvider';
import StoreProvider from '../utils/store/StoreProvider';
import { appWithTranslation } from 'next-i18next';
import TapTop from '../components/common/widgets/Tap-Top';
import { getAccount } from '../utils/account';
import { getImageDownloadUrl } from '../utils/images';
import { Analytics } from "@vercel/analytics/react";
import * as ga from '../utils/ga';

Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

function MyApp({ Component, pageProps, store }) {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const handleRouteChange = (url) => {
      ga.pageview(url);
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1"
        />
        <meta name="google" content="notranslate"/>
        {store && (
          <>
            <title>{store.name}</title>
            
            <meta name="description" content={pageProps?.description ?? store.description}/>

            <meta property="og:type" content="website"/>
            <meta property="og:title" content={pageProps?.title ?? store.name}/>
            <meta property="og:description" content={pageProps?.description ?? store.description}/>
            <meta property="og:image" content={pageProps?.image ?? getImageDownloadUrl(store.image)}/>

            <meta name="twitter:title" content={pageProps?.title ?? store.name}/>
            <meta name="twitter:description" content={pageProps?.description ?? store.description}/>
            <meta name="twitter:image" content={pageProps?.image ?? getImageDownloadUrl(store.image)}/>
          </>
        )}
        
      </Head>
      {isLoading ? (
        <div className="loader-wrapper">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          
          <div>
            <SettingProvider>
              <StoreProvider>
                <CartContextProvider>
                  <FilterProvider>
                    <CategoryProvider>
                      <Component {...pageProps} />
                    </CategoryProvider>
                  </FilterProvider>
                </CartContextProvider>
              </StoreProvider>
            </SettingProvider>
            <ToastContainer />
            <TapTop />
            <Analytics />
          </div>
        </>
      )}
    </>
  );
}

MyApp.getInitialProps = async (appContext) => {
  const { store } = await getAccount(appContext.ctx.req ? appContext.ctx.req : appContext.ctx.res);
  return { store };
}

export default appWithTranslation(MyApp);
