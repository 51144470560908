import cookie from 'cookie';
import Cookies from 'js-cookie';
import { getStore } from '../models/stores/utils';
import * as Sentry from "@sentry/nextjs";

const extractHostname = (url) => {
  if(process.env.OVERRIDE_DOMAIN){
    return process.env.OVERRIDE_DOMAIN;
  }
  var hostname;
  //find & remove protocol (http, ftp, etc.) and get hostname

  if (url.indexOf('//') > -1) {
    hostname = url.split('/')[2];
  } else {
    hostname = url.split('/')[0];
  }

  //find & remove port number
  hostname = hostname.split(':')[0];
  //find & remove "?"
  hostname = hostname.split('?')[0];
  return hostname;
};

const setAccountId = ({ hostname, store }) => {
  if (store && hostname) {
    Cookies.set('accountId', store.accountId);
    Cookies.set('hostname', hostname);
    Cookies.set('store', JSON.stringify(store));
  }
};

const getAccount = async (req) => {
  if(!req){
    return {
      accountId: null,
      hostname: null,
      store: null,
    };
  }
  let cookieData = "";
  if(req && req.headers){
    cookieData = req.headers.cookies ?? "";
  }else if(typeof document !== 'undefined'){
    cookieData = document.cookie ?? "";
  }
  const reqCookie = cookie.parse(cookieData);

  let host = "";
  if(req.headers && req.headers.host){
    host = req.headers.host;
  }else if(typeof window !== 'undefined'){
    host = window.location.host;
  }else{
    return {
      accountId: null,
      hostname: null,
      store: null,
    };
  }
  const hostname = extractHostname(host);
  if (reqCookie.hostname == hostname && reqCookie.store) {
    if (reqCookie.accountId) {
      try {
        const store = JSON.parse(reqCookie.store);
        return {
          accountId: reqCookie.accountId,
          hostname: hostname,
          store: store,
        };
      } catch (e) {
        Sentry.captureException(e);
      }
    }
  }
  const store = await getStore(hostname);
  return {
    accountId: store ? store.accountId : null,
    hostname: hostname,
    store: store,
  };
};
export { getAccount, setAccountId, extractHostname };
