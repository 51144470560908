import React, { useState } from 'react';
import SettingContext from './SettingContext';
const SettingProvider = (props) => {
  const [layoutState, setLayoutState] = useState('RTL');
  const [setLayoutColor] = useState('#ff4c3b');
  const layoutFun = (item) => {
    if (item === 'RTL') {
      document.body.classList.remove('ltr');
      document.body.classList.add('rtl');
      setLayoutState('LTR');
    } else {
      document.body.classList.remove('rtl');
      document.body.classList.add('ltr');
      setLayoutState('RTL');
    }
  };

  const layoutColorFun = (item) => {
    document.documentElement.style.setProperty(
      '--theme-default',
      item.target.value
    );
    setLayoutColor(item.target.value);
  };
  return (
    <SettingContext.Provider
      value={{
        ...props,
        state: layoutState,
        layoutFun: layoutFun,
        layoutColorFun: layoutColorFun,
      }}
    >
      {props.children}
    </SettingContext.Provider>
  );
};

export default SettingProvider;
