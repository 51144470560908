/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import FilterContext from './FilterContext';
import { useRouter } from 'next/router';

const FilterProvider = (props) => {
  const router = useRouter();
  let query = router.query.query;

  const category = router.query.category;

  const [selectedCategory, setSelectedCategory] = useState(
    category ? category : ''
  );
  const [selectedQuery, setSelectedQuery] = useState(query);

  const pushToSearch = () => {
    let params = {};
    if (selectedQuery != undefined && selectedQuery != null) {
      params['query'] = selectedQuery;
    }
    if (selectedCategory) {
      params['category'] = selectedCategory;
    }
    router.push(
      `/search?${Object.keys(params)
        .map((key) => key + '=' + params[key])
        .join('&')}`
    );
  };
  useEffect(() => {
    if (!router.isReady) return;
    if (router.pathname.startsWith('/search')) {
      pushToSearch();
    }
  }, [router.isReady, selectedCategory, selectedQuery]);

  return (
    <FilterContext.Provider
      value={{
        ...props,
        selectedCategory,
        setSelectedQuery,
        setSelectedCategory,
        selectedQuery,
        pushToSearch,
      }}
    >
      {props.children}
    </FilterContext.Provider>
  );
};

export default FilterProvider;
