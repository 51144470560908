import mongoose from 'mongoose';

const connection = {};

async function connect() {
  if (connection.isConnected) {
    return;
  }
  if (mongoose.connections.length > 0) {
    connection.isConnected = mongoose.connections[0].readyState;
    if (connection.isConnected === 1) {
      return;
    }
    await mongoose.disconnect();
  }
  const db = await mongoose.connect(process.env.DB_URI, {
    useNewUrlParser: true,
    useUnifiedTopology: true,
  });
  connection.isConnected = db.connections[0].readyState;
}

async function disconnect() {
  if (connection.isConnected) {
    if (process.env.NODE_ENV === 'production') {
      // await mongoose.disconnect();
      // connection.isConnected = false;
    }
  }
}

function convertDocToObj(doc) {
  doc._id = doc._id.toString();
  if (doc.createdAt) doc.createdAt = doc.createdAt.toString();
  if (doc.updatedAt) doc.updatedAt = doc.updatedAt.toString();
  if (doc.accountId) doc.accountId = doc.accountId.toString();
  if (doc.expiresAt) doc.expiresAt = doc.expiresAt.toString();
  if (doc.color && doc.color.length === 9)
    doc.color = '#' + doc.color.substring(3);
  if (doc.costPrice !== undefined){
    delete doc.costPrice;
  }
  return doc;
}

const db = { connect, disconnect, convertDocToObj };
export default db;
