import React, { useEffect, useState } from 'react';
import CategoryContext from './CategoryContext';
import axios from 'axios';
import Cookies from 'js-cookie';
import * as Sentry from "@sentry/nextjs";

const fetchCategories = async () => {
  try {
    const { data } = await axios.get(`/api/products/categories`);
    Cookies.set('categories', JSON.stringify(data), {
      expires: new Date(new Date().getTime() + 30 * 60 * 1000),
    });
    return data;
  } catch (e) {
    Sentry.captureException(e);
  }
};

const getLocalCategories = async () => {
  try {
    const list = Cookies.get('categories');
    if (!list || list == [] || list == '[]') {
      return await fetchCategories();
    } else {
      return JSON.parse(list);
    }
  } catch (err) {
    return [];
  }
};

const CategoryProvider = (props) => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    async function fetch() {
      const newCategories = await getLocalCategories();
      setCategories(newCategories);
    }
    fetch();
  }, []);

  return (
    <CategoryContext.Provider
      value={{
        ...props,
        categories,
      }}
    >
      {props.children}
    </CategoryContext.Provider>
  );
};

export default CategoryProvider;
