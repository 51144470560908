const getImageDownloadUrl = (imageName) => {
  if (!imageName) {
    return '';
  }
  return (
    'https://storage.googleapis.com/' +
    process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET +
    '/' +
    imageName
  );
};
export { getImageDownloadUrl };
