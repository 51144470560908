import mongoose from 'mongoose';

const storeSchema = new mongoose.Schema(
  {
    _id: { type: String, required: true },
    isActive: { type: Boolean, required: true },
    name: { type: String, required: true },
    phone: { type: String },
    accountId: { type: String, required: true },
    image: { type: String },
    address: { type: String },
    currency: { type: String, required: true },
    domains: [{ type: String, required: true }],
    shipping: { type: Boolean, required: true },
    payment: [
      {
        obs: { type: String, required: false },
        type: String,
      },
    ],
    delayProduction: { type: String, required: false },
    finishOrder: { type: String, required: false },
    whatsapp: { type: String, required: false },
    facebook: { type: String, required: false },
    instagram: { type: String, required: false },
    pixelFacebook: { type: String, required: false },
    googleCode: { type: String, required: false },
    shippingMethods: [
      {
        description: { type: String, required: false },
        id: { type: String, required: true },
        enabled: { type: Boolean, required: true },
        showInCatalog: { type: Boolean, required: true },
        name: { type: String, required: true },
        defaultValue: { type: Number, required: true },
      },
    ],
    color: { type: String, required: true },
    outStockProducts: { type: String, required: true },
    takeaway: { type: Boolean, require: true },
    expiresAt: { type: Date, required: true },
    description: { type: String, required: false },
    onlyCatalog: { type: Boolean, required: true, default: false },
    allowGuest: { type: Boolean, required: true, default: false },
  },
  {
    timestamps: true,
  }
);

let Store;
try {
  Store = mongoose.connection.model('stores');
} catch (e) {
  Store = mongoose.model('stores', storeSchema, 'stores');
}

export default Store;
